export default [
    {
        path: '/orders',
        name: 'orders-index',
        component: () => import('@/views/orders/Index.vue'),
        meta: {
            pageTitle: 'Eladások',
            breadcrumb: [
                {
                    text: 'Eladások',
                    active: true,
                },
            ],
        },
    },
]
