// import axios from "axios";
import RemoveLogService from '@/services/removeLogService'

const defaultState = () => ({
  start: false,
  type: null,
  paid: false,
})

const state = defaultState

const actions = {
  async resetPaymentState({ commit }) {
    await commit('RESET_PAYMENT_STATE')
  },
  async startPayment({ commit }, payload) {
    commit('SET_PAYMENT_START', payload)
  },
  async setPaymentType({ commit }, payload) {
    commit('SET_PAYMENT_TYPE', payload)
  },
  async setIsPaid({ commit }, payload) {
    commit('SET_PAID', payload)
  },
  async closePayment({ commit }, payload) {
    const serverResponse = await RemoveLogService.startRemoveLog(payload)
    commit('CLOSE_PAYMENT')
  },
}

const mutations = {
  RESET_PAYMENT_STATE: state => Object.assign(state, defaultState()),
  SET_PAYMENT_TYPE: (state, payload) => state.type = payload,
  SET_PAYMENT_START: (state, payload) => state.start = payload,
  SET_PAID: (state, payload) => state.paid = payload,
  CLOSE_PAYMENT: state => state.start = false,
}

const getters = {
  paymentIsStart: state => state.start,
  getPaymentType: state => state.type,
  isPaid: state => state.paid,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
