import ScrappingService from '../../services/scrappingService'

const state = {
  scrappings: [],
}

const getters = {
  allScrappings: state => state.scrappings,
}

const actions = {
  async fetchScrappings({ commit }) {
    const serverResponse = await ScrappingService.getAllScrapping()
    await commit('setScrappings', serverResponse.data.data)
  },
  async createScrapping({ commit }, formData) {
    const serverResponse = await ScrappingService.createScrapping(formData)
    // await commit('setCategories', serverResponse.data.data)
  },
}

const mutations = {
  setScrappings: (state, scrappings) => (state.scrappings = scrappings),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
