import WarehouseService from '../../services/warehouseService'

const state = {
  warehouses: [],
}

const getters = {
  allWarehouses: state => state.warehouses,
}

const actions = {
  async fetchWarehouses({ commit }) {
    const serverResponse = await WarehouseService.getAllWarehouses()
    await commit('setWarehouses', serverResponse.data.data)
  },
  async createWarehouse({ commit }, formData) {
    const serverResponse = await WarehouseService.createWarehouse(formData)
    // await commit('setWarehouses', serverResponse.data.data)
  },
  async updateWarehouse({ commit }, formData) {
    const serverResponse = await WarehouseService.updateWarehouse(formData)
    // await commit('setWarehouses', serverResponse.data.data)
  },
  async deleteWarehouse({ commit }, id) {
    const serverResponse = await WarehouseService.deleteWarehouse(id)
    commit('removeWarehouse', id)
  },
  async getWarehouse({ commit }, id) {
    const serverResponse = await WarehouseService.getWarehouse(id)
    return await serverResponse.data.data
  },
}

const mutations = {
  setWarehouses: (state, warehouses) => (state.warehouses = warehouses),
  removeWarehouse: (state, id) => (state.warehouses = state.warehouses.filter(warehouse => warehouse.id !== id)),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
