import ApiClient from '@/libs/apiClient'

export default {
  fetchDailyData() {
    return ApiClient.get('daily/data')
  },
  fetchClosingHistory(payload) {
    return ApiClient.post('daily/data-history', payload)
  },
  startDailyClose(payload) {
    return ApiClient.post('daily/closing', payload)
  },
}
