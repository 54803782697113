import billingService from '@/services/billingService'

const defaultState = () => ({
  showPanel: false,
  hasBill: false,
  billId: null,
  stornoId: null,
  publicUrls: {
    bill: null,
    storno: null,
  },
  details: {
    partner_name: '',
    post_code: '',
    city: '',
    address: '',
    tax_number: '',
    partner_emails: [],
    payment_method: '',
  },
})
const state = defaultState

const actions = {
  async resetBillingState({ commit }) {
    await commit('RESET_BILLING_STATE')
  },
  async showBillingPanel({ commit }, payload) {
    await commit('SHOW_BILLING_PANEL', payload)
  },
  async setBillingName({ commit }, payload) {
    await commit('SET_BILLING_NAME', payload)
  },
  async setBillingTaxNumber({ commit }, payload) {
    await commit('SET_BILLING_TAX_NUMBER', payload)
  },
  async setBillingPostCode({ commit }, payload) {
    await commit('SET_BILLING_POST_CODE', payload)
  },
  async setBillingCity({ commit }, payload) {
    await commit('SET_BILLING_CITY', payload)
  },
  async setBillingAddress({ commit }, payload) {
    await commit('SET_BILLING_ADDRESS', payload)
  },
  async setBillingEmail({ commit }, payload) {
    await commit('SET_BILLING_EMAIL', payload)
  },
  async setPaymentMethod({ commit }, payload) {
    await commit('SET_PAYMENT_METHOD', payload)
  },
  async setHasBill({ commit }, payload) {
    await commit('SET_HAS_BILL', payload)
  },
  async setBillId({ commit }, payload) {
    await commit('SET_BILL_ID', payload)
  },
  async setStornoBillId({ commit }, payload) {
    await commit('SET_STORNO_ID', payload)
  },
  async createBill({ state }, payload) {
    const newPayload = Object.assign(state.details, { products: payload.orderedProducts })
    return await billingService.createDocument(newPayload)
  },
  async cancelBill({ state }) {
    return await billingService.cancelDocument(state.billId)
  },
  async setBillPublicUrl({ commit }, payload) {
    const serverResponse = await billingService.getDocumentPublicUrl(payload)
    await commit('SET_BILL_PUBLIC_URL', serverResponse.data.data)
  },
  async setStornoPublicUrl({ commit }, payload) {
    const serverResponse = await billingService.getDocumentPublicUrl(payload)
    await commit('SET_STORNO_PUBLIC_URL', serverResponse.data.data)
  },
}

const mutations = {
  RESET_BILLING_STATE: state => Object.assign(state, defaultState()),
  SHOW_BILLING_PANEL: (state, payload) => state.showPanel = payload,
  SET_BILLING_NAME: (state, payload) => state.details.partner_name = payload,
  SET_BILLING_TAX_NUMBER: (state, payload) => state.details.tax_number = payload,
  SET_BILLING_POST_CODE: (state, payload) => state.details.post_code = payload,
  SET_BILLING_CITY: (state, payload) => state.details.city = payload,
  SET_BILLING_ADDRESS: (state, payload) => state.details.address = payload,
  SET_BILLING_EMAIL: (state, payload) => state.details.partner_emails = [payload],
  SET_PAYMENT_METHOD: (state, payload) => state.details.payment_method = payload,
  SET_HAS_BILL: (state, payload) => state.hasBill = payload,
  SET_BILL_ID: (state, payload) => state.billId = payload,
  SET_STORNO_ID: (state, payload) => state.stornoId = payload,
  SET_BILL_PUBLIC_URL: (state, payload) => state.publicUrls.bill = payload,
  SET_STORNO_PUBLIC_URL: (state, payload) => state.publicUrls.storno = payload,
}

const getters = {
  getBillingName: state => state.details.partner_name,
  getBillingTaxNumber: state => state.details.tax_number,
  getBillingPostCode: state => state.details.post_code,
  getBillingCity: state => state.details.city,
  getBillingAddress: state => state.details.address,
  getBillingEmail: state => state.details.partner_emails,
  getBillingDetails: state => state.details,
  isHasBill: state => state.hasBill,
  getBillId: state => state.billId,
  getStornoBillId: state => state.stornoId,
  getBillingPanelVisibility: state => state.showPanel,
  getBillPublicUrl: state => state.publicUrls.bill,
  getStornoPublicUrl: state => state.publicUrls.storno,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
