import ApiClient from '@/libs/apiClient'

export default {
  fetchWarehouseSales() {
    return ApiClient.get('statistics/warehouse-sales')
  },
  fetchWarehouseSalesBetweenDates(payload) {
    const searchQuery = new URLSearchParams(payload).toString()
    return ApiClient.get(`statistics/warehouse-sales-between-dates?${searchQuery}`)
  },
  fetchRotting() {
    return ApiClient.get('statistics/rotting')
  },
}
