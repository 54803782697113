export default [
  {
    path: '/warehouses',
    name: 'warehouses-index',
    component: () => import('@/views/warehouses/Index.vue'),
    meta: {
      pageTitle: 'Raktárak',
      breadcrumb: [
        {
          text: 'Raktárak',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warehouses/create',
    name: 'warehouses-create',
    component: () => import('@/views/warehouses/Create.vue'),
    meta: {
      pageTitle: 'Raktár létrehozás',
      breadcrumb: [
        {
          text: 'Raktár létrehozás',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warehouses/edit/:id',
    name: 'warehouses-edit',
    component: () => import('@/views/warehouses/Edit.vue'),
    meta: {
      pageTitle: 'Raktár szerkesztése',
      breadcrumb: [
        {
          text: 'Raktár szerkesztése',
          active: true,
        },
      ],
    },
  },
]
