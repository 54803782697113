import ApiClient from '@/libs/apiClient'

export default {
  getAllRemoveLog() {
    return ApiClient.get('remove', {
      params: {
        per_page: 50,
        page: 1,
      },
    })
  },
  startRemoveLog(payload) {
    return ApiClient.post('remove', payload)
  },
}
