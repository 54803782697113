import IncomeLogService from '../../services/incomeLogService'

const state = {
  incomeLogs: [],
}

const getters = {
  allIncomeLogs: state => state.incomeLogs,
}

const actions = {
  async fetchIncomeLogs({ commit }) {
    const serverResponse = await IncomeLogService.getAllIncomeLog()
    await commit('setIncomeLogs', serverResponse.data.data)
  },
  async createIncomeLog({ commit }, formData) {
    const serverResponse = await IncomeLogService.createIncomeLog(formData)
    // await commit('setCategories', serverResponse.data.data)
  },
}

const mutations = {
  setIncomeLogs: (state, incomeLogs) => (state.incomeLogs = incomeLogs),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
