// import axios from "axios";

const defaultState = () => ({
  items: [],
  sumPrice: 0,
  vat: 0,
  warehouse_id: null,
})

const state = defaultState

const actions = {
  async resetOrderState({ commit }) {
    await commit('RESET_ORDER_STATE')
  },

  async setWarehouseId({ commit }, payload) {
    await commit('SET_WAREHOUSE_ID', payload)
  },

  async addProduct({ commit }, payload) {
    commit('ADD_NEW_PRODUCT', payload)
  },

  async changeSumPrice({ commit }, payload) {
    commit('CHANGE_SUM_PRICE', payload)
  },

  async changeVat({ commit }, payload) {
    commit('CHANGE_VAT', payload)
  },

  async removeProductFromOrder({ commit }, product) {
    commit('REMOVE_PRODUCTS_FROM_ORDER', product.id)
  },
}

const mutations = {
  RESET_ORDER_STATE: state => Object.assign(state, defaultState()),
  SET_WAREHOUSE_ID: (state, payload) => {
    state.warehouse_id = payload
  },
  ADD_NEW_PRODUCT: (state, product) => {
    const item = state.items.find(item => item.product.id === product.id)
    const indexOf = state.items.indexOf(item)

    if (indexOf === -1) {
      state.items.unshift({
        product,
        qty: 1,
      })
    } else {
      state.items[indexOf].qty += 1
    }
  },

  CHANGE_SUM_PRICE: (state, payload) => {
    if (payload.type === 'add') {
      state.sumPrice += payload.value
    } else {
      state.sumPrice -= payload.value
    }
  },

  CHANGE_VAT: (state, payload) => {
    if (payload.type === 'add') {
      state.vat += payload.value
    } else {
      state.vat -= payload.value
    }
  },
  REMOVE_PRODUCTS_FROM_ORDER: (state, id) => (state.items = state.items.filter(item => item.product.id !== id)),
}

const getters = {
  getAllOrdersProducts: state => state.items,
  getSumPrice: state => state.sumPrice,
  getVat: state => Math.round(state.vat),
  getWarehouseId: state => state.warehouse_id,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
