import ApiClient from "@/libs/apiClient";

export default {
  getAllActivity() {
    return ApiClient.post("logs", {
      log_name: "system.cash_register.removeProductFromOrder",
      per_page: 100,
      page: 1,
    });
  },
  fetchProductHistory(productId) {
    return ApiClient.post("logs/productHistory", {
      product_id: parseInt(productId),
    });
  },
};
