import ApiClient from '@/libs/apiClient'

export default {
  createDocument(payload) {
    return ApiClient.post('billing/document', payload)
  },
  cancelDocument(payload) {
    return ApiClient.delete(`billing/document/${payload}`)
  },
  getDocumentPublicUrl(payload) {
    return ApiClient.get(`billing/document/${payload}/public-url`)
  },
}
