import ApiClient from '@/libs/apiClient'

export default {
  getAllIncomeLog() {
    return ApiClient.get('income', {
      params: {
        per_page: 500,
        page: 1,
      },
    })
  },
  createIncomeLog(formData) {
    return ApiClient.post('income', formData)
  },
}
