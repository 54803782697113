// --- For authentication ---
import ApiClient from '@/libs/apiClient'

const state = {
  token: null,
  user: null,
}
const getters = {
  authenticated(state) {
    return state.token && state.user
  },
  user(state) {
    return state.user
  },
}
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_USER(state, data) {
    state.user = data
  },
}
const actions = {
  async signIn({ dispatch }, credentials) {
    const response = await ApiClient.post('login', credentials)
    return await dispatch('attempt', response.data.token)
  },

  async register({ dispatch }, credentials) {
    const response = await ApiClient.post('register', credentials)
    return await dispatch('attempt', response.data.token)
  },

  async refreshUser({ commit }) {
    const response = await ApiClient.get('me')
    return await commit('SET_USER', response.data.data)
  },

  async attempt({ commit, state }, token) {
    if (token) {
      await commit('SET_TOKEN', token)
    }

    if (!state.token) {
      return
    }

    try {
      const response = await ApiClient.get('me')
      await commit('SET_USER', response.data.data)
    } catch (e) {
      await commit('SET_TOKEN', null)
      await commit('SET_USER', null)
    }
  },
  signOut({ commit }) {
    return ApiClient.post('logout').then(() => {
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
    })
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
