// import axios from "axios";
import DailyClosingService from '@/services/dailyClosingService'

const defaultState = () => ({
  history: [],
})

const state = defaultState()

const actions = {
  async resetDailyClosingState({ commit }) {
    await commit('RESET_DALY_CLOSING_STATE')
  },
  async fetchClosingHistory({ commit }, payload) {
    const serverResponse = await DailyClosingService.fetchClosingHistory(payload)
    if (serverResponse.status === 200) {
      await commit('SET_CLOSING_HISTORY', serverResponse.data.data)
    }
  },

}

const mutations = {
  RESET_DALY_CLOSING_STATE: state => Object.assign(state, defaultState()),
  SET_CLOSING_HISTORY: (state, payload) => state.history = payload,
}

const getters = {
  getClosingHistory: state => state.history,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
