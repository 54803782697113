import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

require('@/store/auth/subscriber')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formattedPrice', value => `${Math.ceil((value / 5) * 5).toLocaleString('HU')} Ft`)

store.dispatch('attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
  })
})
