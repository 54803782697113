export default [
  {
    path: '/categories',
    name: 'categories-index',
    component: () => import('@/views/categories/Index.vue'),
    meta: {
      pageTitle: 'Kategóriák',
      breadcrumb: [
        {
          text: 'Kategóriák',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/create',
    name: 'categories-create',
    component: () => import('@/views/categories/Create.vue'),
    meta: {
      pageTitle: 'Kategória létrehozás',
      breadcrumb: [
        {
          text: 'Kategória létrehozás',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/edit/:id',
    name: 'categories-edit',
    component: () => import('@/views/categories/Edit.vue'),
    meta: {
      pageTitle: 'Kategória szerkesztése',
      breadcrumb: [
        {
          text: 'Kategória szerkesztése',
          active: true,
        },
      ],
    },
  },
]
