import ApiClient from '@/libs/apiClient'

export default {
  getAllWarehouses() {
    return ApiClient.get('warehouses')
  },
  deleteWarehouse(id) {
    return ApiClient.delete(`warehouses/${id}`)
  },
  createWarehouse(formData) {
    return ApiClient.post('warehouses', formData)
  },
  updateWarehouse(formData, id) {
    return ApiClient.put(`warehouses/${formData.id}`, formData)
  },
  getWarehouse(id) {
    return ApiClient.get(`warehouses/${id}`)
  },
}
