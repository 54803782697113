import TransferLogService from '../../services/transferLogService'

const state = {
  transferLogs: [],
}

const getters = {
  allTransferLogs: state => state.transferLogs,
}

const actions = {
  async fetchTransferLogs({ commit }) {
    const serverResponse = await TransferLogService.getAllTransferLog()
    await commit('setTransferLogs', serverResponse.data.data)
  },
  async createTransferLog({ commit }, formData) {
    const serverResponse = await TransferLogService.createTransferLog(formData)
    // await commit('setCategories', serverResponse.data.data)
  },
}

const mutations = {
  setTransferLogs: (state, transferLogs) => (state.transferLogs = transferLogs),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
