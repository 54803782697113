import CategoryService from '../../services/categoryService'

const state = {
  categories: [],
  loading: true,
  selectedCategory: null,
}

const actions = {
  async resetCategoriesState({ commit }) {
    await commit('RESET_CATEGORIES_STATE')
  },
  async categoryLoading({ commit }, payload) {
    await commit('CATEGORY_LOADING', payload)
  },
  async setSelectedCategory({ commit }, category) {
    const serverResponse = await CategoryService.getAllCategoriesByCategory(
      category ? category.id : null,
    )
    await commit('SET_CATEGORIES', serverResponse.data.data)
    await commit('SET_SELECTED_CATEGORIES', category)
  },
  async fetchCategories({ commit }) {
    const serverResponse = await CategoryService.getAllCategories()
    await commit('setCategories', serverResponse.data.data)
  },
  async createCategory({ commit }, formData) {
    const serverResponse = await CategoryService.createCategory(formData)
    // await commit('setCategories', serverResponse.data.data)
  },
  async updateCategory({ commit }, formData) {
    const serverResponse = await CategoryService.updateCategory(formData)
    // await commit('setCategories', serverResponse.data.data)
  },
  async deleteCategory({ commit }, id) {
    const serverResponse = await CategoryService.deleteCategory(id)
    commit('removeCategory', id)
  },
  async getCategory({ commit }, id) {
    const serverResponse = await CategoryService.getCategory(id)
    return await serverResponse.data.data
  },
}

const mutations = {
  setCategories: (state, categories) => (state.categories = categories),
  removeCategory: (state, id) => (state.categories = state.categories.filter(category => category.id !== id)),
  RESET_CATEGORIES_STATE: state => Object.assign(state, defaultState()),
  CATEGORY_LOADING: (state, payload) => state.loading = payload,
  SET_CATEGORIES: (state, categories) => (state.categories = categories),
  SET_SELECTED_CATEGORIES: (state, category) => {
    state.selectedCategory = category
  },
}

const getters = {
  allCategories: state => state.categories,
  categoryIsLoading: state => state.loading,
  getSelectedCategory: state => state.selectedCategory,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
