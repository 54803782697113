import Vue from 'vue'

// axios
import axios from 'axios'

const ApiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 10000,
})

Vue.prototype.$http = ApiClient

export default ApiClient
