import ApiClient from '@/libs/apiClient'

export default {
  getAllTransferLog() {
    return ApiClient.get('transfer', {
      params: {
        per_page: 500,
        page: 1,
      },
    })
  },
  createTransferLog(formData) {
    return ApiClient.post('transfer', formData)
  },
}
