import removeLogService from '@/services/removeLogService'

const defaultState = () => ({
  items: [],
  logs: [],
})

const state = defaultState()

const actions = {
  async resetOrderState({ commit }) {
    await commit('RESET_ORDER_STATE')
  },
  async addProductToItems({ commit }, payload) {
    await commit('ADD_PRODUCT_TO_ITEMS', payload)
  },
  async removeProductFromItems({ commit }, payload) {
    await commit('REMOVE_PRODUCTS_FROM_ITEMS', payload)
  },
  async fetchRemoveLogs({ commit }) {
    const serverResponse = await removeLogService.getAllRemoveLog()
    await commit('SET_REMOVE_LOGS', serverResponse.data.data)
  },
  async setProductName({ commit }, payload) {
    await commit('SET_PRODUCT_NAME', payload)
  },
}

const mutations = {
  RESET_ORDER_STATE: state => Object.assign(state, defaultState()),
  SET_REMOVE_LOGS: (state, payload) => state.logs = payload,
  ADD_PRODUCT_TO_ITEMS: (state, payload) => state.items.push(payload),
  REMOVE_PRODUCTS_FROM_ITEMS: (state, index) => {
    state.items.splice(index, 1)
  },
  SET_PRODUCT_NAME: (state, payload) => {
    state.items[payload.index].name = payload.name
  },
}

const getters = {
  getAllItems: state => state.items,
  allLogs: state => state.logs,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
