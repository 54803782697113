import ApiClient from '@/libs/apiClient'

export default {
  getAllScrapping() {
    return ApiClient.get('scrapping', {
      params: {
        per_page: 5,
        page: 1,
      },
    })
  },
  createScrapping(formData) {
    return ApiClient.post('scrapping', formData)
  },
}
