import ApiClient from '@/libs/apiClient'

export default {
  getAllCategoriesByCategory(categoryId) {
    if (!categoryId) {
      return ApiClient.get('categories', {
        params: {
          root: true,
        },
      })
    }
    return ApiClient.get('categories', {
      params: {
        parent_id: categoryId,
      },
    })
  },
  getAllCategories() {
    return ApiClient.get('categories')
  },
  deleteCategory(id) {
    return ApiClient.delete(`categories/${id}`)
  },
  createCategory(formData) {
    return ApiClient.post('categories', formData)
  },
  updateCategory(formData, id) {
    return ApiClient.put(`categories/${formData.id}`, formData)
  },
  getCategory(id) {
    return ApiClient.get(`categories/${id}`)
  },
}
