import StatisticsService from '@/services/statisticsService'

const defaultState = () => ({
  warehouseSales: [],
  warehouseSalesBetweenDates: [],
  chartDateRange: {
    from: null,
    to: null,
  },
  warehouseForChart: null,
  rotting:
{
}
  ,
})
const state = defaultState

const actions = {
  async resetStatisticState({ commit }) {
    await commit('RESET_STATISTIC_STATE')
  },
  async fetchWarehouseSales({ commit }) {
    const serverResponse = await StatisticsService.fetchWarehouseSales()
    await commit('SET_WAREHOUSE_SALES', serverResponse.data.data)
  },
  async setWarehouseForChart({ commit }, payload) {
    await commit('SET_WAREHOUSE_FOR_CHART', payload)
  },
  async setTimeRangeForChart({ commit }, payload) {
    await commit('SET_TIME_RANGE_FOR_CHART', payload)
  },
  async fetchWarehouseSalesBetweenDates({ commit }, payload) {
    const serverResponse = await StatisticsService.fetchWarehouseSalesBetweenDates(payload)
    await commit('RESET_WAREHOUSE_SALES_BETWEEN_DATES')
    await commit('SET_WAREHOUSE_SALES_BETWEEN_DATES', serverResponse.data.data)
  },
  async fetchRotting({ commit }) {
    const serverResponse = await StatisticsService.fetchRotting()
    await commit('SET_ROTTING', serverResponse.data.data)
  },
}

const mutations = {
  RESET_STATISTIC_STATE: state => Object.assign(state, defaultState()),
  SET_WAREHOUSE_SALES: (state, payload) => state.warehouseSales = payload,
  SET_WAREHOUSE_SALES_BETWEEN_DATES: (state, payload) => state.warehouseSalesBetweenDates = payload,
  RESET_WAREHOUSE_SALES_BETWEEN_DATES: state => state.warehouseSalesBetweenDates = [],
  SET_ROTTING: (state, payload) => state.rotting = payload,
  SET_WAREHOUSE_FOR_CHART: (state, payload) => state.warehouseForChart = payload,
  SET_TIME_RANGE_FOR_CHART: (state, payload) => {
    state.chartDateRange.from = payload[0]
    state.chartDateRange.to = payload[1]
  },
}

const getters = {
  getWarehouseSales: state => state.warehouseSales,
  getWarehouseSalesBetweenDates: state => state.warehouseSalesBetweenDates,
  getRotting: state => state.rotting,
  getWarehouseForChart: state => state.warehouseForChart,
  getTimeRangeForChart: state => state.chartDateRange,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
