import UserService from '../../services/userService'

const state = {
  users: [],
}

const getters = {
  allUsers: state => state.users,
}

const actions = {
  async fetchUsers({ commit }) {
    const serverResponse = await UserService.getAllUsers()
    await commit('setUsers', serverResponse.data.data)
  },
  async createUser({ commit }, formData) {
    const serverResponse = await UserService.createUser(formData)
    // await commit('setUsers', serverResponse.data.data)
  },
  async updateUser({ commit }, formData) {
    const serverResponse = await UserService.updateUser(formData)
    // await commit('setUsers', serverResponse.data.data)
  },
  async deleteUser({ commit }, id) {
    const serverResponse = await UserService.deleteUser(id)
    commit('removeUser', id)
  },
  async getUser({ commit }, id) {
    const serverResponse = await UserService.getUser(id)
    return await serverResponse.data.data
  },
}

const mutations = {
  setUsers: (state, users) => (state.users = users),
  removeUser: (state, id) => (state.users = state.users.filter(user => user.id !== id)),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
