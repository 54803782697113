import Vue from "vue";
import VueRouter from "vue-router";
import auth from "./auth";
import authMiddleare from "../middleware/auth";
import products from "./products";
import users from "./users";
import warehouses from "./warehouses";
import categories from "./categories";
import retail from "./retail";
import orders from "./orders";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("@/views/dashboard/Ecommerce.vue"),
            meta: {
                middleware: authMiddleare,
                pageTitle: "Home",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/stock",
            name: "stock-index",
            component: () => import("@/views/stocks/Index.vue"),
            meta: {
                pageTitle: "Készlet",
                breadcrumb: [
                    {
                        text: "Készlet",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/settings",
            name: "settings",
            component: () => import("@/views/Settings.vue"),
            meta: {
                pageTitle: "Beállítások",
                breadcrumb: [
                    {
                        text: "Beállítások",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("@/views/profile/Index.vue"),
            meta: {
                pageTitle: "Profil",
                breadcrumb: [
                    {
                        text: "Profil",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/cash-register-activities",
            name: "cash-register-activities",
            component: () => import("@/views/cash-register-activities/Index.vue"),
            meta: {
                pageTitle: "Kassza tevékenységek",
                breadcrumb: [
                    {
                        text: "Kassza tevékenységek",
                        active: true,
                    },
                ],
            },
        },
        ...auth,
        ...users,
        ...products,
        ...warehouses,
        ...categories,
        ...retail,
        ...orders,
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/error/Error404.vue"),
            meta: {
                layout: "full",
            },
        },
        {
            path: "*",
            redirect: "error-404",
        },
    ],
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;
