import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import products from './modules/products'
import order from './modules/order'
import billing from './modules/billing'
import users from './modules/users'
import payment from './modules/payment'
import warehouses from './modules/warehouses'
import categories from './modules/categories'
import incomeLogs from './modules/incomeLogs'
import removeLogs from './modules/removeLogs'
import transferLogs from './modules/transferLogs'
import scrapping from './modules/scrapping'
import auth from './auth/auth'
import statistics from './modules/statistics'
import dailyClosing from './modules/dailyClosing'
import activityLogs from './modules/activityLogs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    products,
    order,
    billing,
    payment,
    warehouses,
    categories,
    incomeLogs,
    removeLogs,
    transferLogs,
    scrapping,
    users,
    auth,
    statistics,
    dailyClosing,
    activityLogs,
  },
  strict: process.env.DEV,
})
