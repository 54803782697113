import activityLogsService from "@/services/activityLogsService";

const defaultState = () => ({
  logs: [],
  productHistory: [],
});

const state = defaultState();

const actions = {
  async fetchLogs({ commit }) {
    const serverResponse = await activityLogsService.getAllActivity();
    await commit("SET_ACTIVITY_LOGS", serverResponse.data.data);
  },
  async fetchProductHistory({ commit }, productId) {
    const serverResponse = await activityLogsService.fetchProductHistory(productId);
    await commit("SET_PRODUCT_HISTORY", serverResponse.data.data);
  },
};

const mutations = {
  SET_ACTIVITY_LOGS: (state, payload) => (state.logs = payload),
  SET_PRODUCT_HISTORY: (state, payload) => (state.productHistory = payload),
};

const getters = {
  getAllLogs: (state) => state.logs,
  getProductHistory: (state) => state.productHistory,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
