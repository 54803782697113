export default [
  {
    path: '/users',
    name: 'users-index',
    component: () => import('@/views/users/Index.vue'),
    meta: {
      pageTitle: 'Felhasználók',
      breadcrumb: [
        {
          text: 'Felhasználók',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/Create.vue'),
    meta: {
      pageTitle: 'Felhasználó létrehozás',
      breadcrumb: [
        {
          text: 'Felhasználó létrehozás',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users/Edit.vue'),
    meta: {
      pageTitle: 'Felhasználó szerkesztése',
      breadcrumb: [
        {
          text: 'Felhasználó szerkesztése',
          active: true,
        },
      ],
    },
  },
]
