import ApiClient from '@/libs/apiClient'

export default {
  getAllUsers() {
    return ApiClient.get('users')
  },
  deleteUser(id) {
    return ApiClient.delete(`users/${id}`)
  },
  createUser(formData) {
    return ApiClient.post('users', formData)
  },
  updateUser(formData, id) {
    return ApiClient.put(`users/${formData.id}`, formData)
  },
  getUser(id) {
    return ApiClient.get(`users/${id}`)
  },
}
