export default [
  {
    path: '/products',
    name: 'products-index',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      pageTitle: 'Termékek kezelése',
      breadcrumb: [
        {
          text: 'Termékek kezelése',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/create',
    name: 'products-create',
    component: () => import('@/views/products/Create.vue'),
    meta: {
      pageTitle: 'Termék létrehozás',
      breadcrumb: [
        {
          text: 'Termék létrehozás',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/edit/:id',
    name: 'products-edit',
    component: () => import('@/views/products/Edit.vue'),
    meta: {
      pageTitle: 'Termék szerkesztése',
      breadcrumb: [
        {
          text: 'Termék szerkesztése',
          active: true,
        },
      ],
    },
  },
]
