export default [
  {
    path: '/retail/sale',
    name: 'sale',
    component: () => import('@/views/retail/Sale.vue'),
    meta: {
      pageTitle: 'Értékesítés',
      breadcrumb: [
        {
          text: 'Értékesítés',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retail/sale/checkout',
    name: 'sale-checkout',
    component: () => import('@/views/retail/SaleCheckout.vue'),
    meta: {
      pageTitle: 'Értékesítés',
      breadcrumb: [
        {
          text: 'Értékesítés',
          active: false,
        },
        {
          text: 'Fizetés',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retail/inward-handling',
    name: 'inward-handling',
    component: () => import('@/views/retail/InwardHandling.vue'),
    meta: {
      pageTitle: 'Bevételezés',
      breadcrumb: [
        {
          text: 'Bevételezés',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retail/scrapping',
    name: 'scrapping',
    component: () => import('@/views/retail/Scrapping.vue'),
    meta: {
      pageTitle: 'Selejtezés',
      breadcrumb: [
        {
          text: 'Selejtezés',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retail/transfer',
    name: 'transfer',
    component: () => import('@/views/retail/Transfer.vue'),
    meta: {
      pageTitle: 'Raktárközi mozgatás',
      breadcrumb: [
        {
          text: 'Raktárközi mozgatás',
          active: true,
        },
      ],
    },
  },
]
